@import "../../global.scss";

.intro {
    background-color: white;
    display: flex;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }

    .left {
        flex: 0.5;
        overflow: hidden;
        display: flex;//mine
        align-items: flex-start;//mine
        justify-content:center;//mine

        .imgContainer {
            width: 800px;
            height: 800px;
            // background: linear-gradient(45deg, #00ffb6 0%, #000fa3 100%);
            background: linear-gradient(45deg, #A9F1DF 0%, #FFBFFB 100%);
            border-radius: 50%;
            box-shadow: 0 0 20px #000;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            // border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;
            animation: liquid 6s ease-in-out infinite;
            
            @keyframes liquid {
                0%{
                    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;;
                    background-size: 100% 100%;
                }
                50%{
                    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;;
                    background-size: 300% 150%;
                }
                100%{
                    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;;
                    background-size: 100% 100%;
                }
            }

            @include mobile {
                align-items: flex-start;
                // width: 100vw;
                height: 100vh;
            }

            img{
                width: 90%;

                @include mobile {
                    height: 50%;
                    width: auto;
                }
            }
        }
    }

    .right {
        flex: 0.5;
        position: relative;

        .wrapper {
            // width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .wrapperDetails {
                margin-top: 45px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                @include mobile {
                    padding: 0 20px;
                }

                .detail{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top:5px;

                    a{
                        .detailIcon {
                            padding-right: 10px;
                        }

                        &:link, &:visited {
                            text-decoration: none;
                            color:black;
                        }
                    }


                    .detailText {
                        width: 80%;
                    }
                }
            }

            @include mobile {
                padding-left: 0;
                align-items: center;
            }

            h1 {
                font-size: 60px;
                margin: 10px 0;

                @include mobile {
                    font-size: 30px;
                    text-align: center;
                }
            }

            h2 {
                font-size: 35px;

                @include mobile {
                    font-size: 25px;
                }
            }

            h3 {
                font-size: 30px;

                @include mobile {
                    font-size: 20px;
                }

                span {
                    font-size: inherit;
                    // color: #7aa8a6;
                    color:#A4B1DF;
                    opacity: 0;
                    animation: drop 0.4s linear forwards;

                    &:nth-child(2) {
                        animation-delay: 0.2s;
                    }
                    &:nth-child(3) {
                        animation-delay: 0.4s;
                    }
                    &:nth-child(4) {
                        animation-delay: 0.6s;
                    }
                    &:nth-child(5) {
                        animation-delay: 0.8s;
                    }
                    &:nth-child(6) {
                        animation-delay: 1s;
                    }
                    &:nth-child(7) {
                        animation-delay: 1.2s;
                    }
                    &:nth-child(8) {
                        animation-delay: 1.4s;
                    }
                    &:nth-child(9) {
                        animation-delay: 1.6s;
                    }
                    &:nth-child(10) {
                        animation-delay: 1.8s;
                    }
                }

                @keyframes drop {
                    0% {
                        transform: translateY(-200px) scaleY(0.9);
                    }
                    5% {
                        opacity: 0.7;
                    }
                    50% {
                        transform: translateY(0px) scaleY(1);
                        opacity: 1;
                    }
                    65% {
                        transform: translateY(-17px) scaleY(0.9);
                    }
                    75% {
                        transform: translateY(-22px) scaleY(0.9);
                    }
                    100% {
                        transform: translateY(0px) scaleY(1);
                        opacity: 1;
                    }
                }

                .ityped-cursor {
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    50%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 0;
                    }
                }
            }
        }

        .downIcon {
            position: absolute;
            bottom: 10px;
            left: 40%;

            img {
                width: 30px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }
        }
    }
}