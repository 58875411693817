.form {
    display: flex;
    flex-direction: column;
    width: 280px;
    margin-top: 10px;
    
    input,textarea {
        padding: 15px;
        margin: 10px 0;
        border-radius: 5px;
        border: 1px solid gray;
    }
    
    label {
    font-size: 12px;
    color: gray;
    }
    

  }